import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Page from "../../components/Page";
import SEO from "../../components/SEO";

import { homeowners } from "../../helpers/navigation";

const ReviewsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviewsImg: file(
        relativePath: { eq: "homeowners/Img_HouseHolders_Feedbacks.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <Page
      page="Reviews"
      pageName="Відгуки"
      title="Відгуки"
      parameters={homeowners}
    >
      <SEO title="Відгуки" />

      <div className="Page_main_block">
        <Img
          fluid={data.reviewsImg.childImageSharp.fluid}
          className="Page_main_block_img-right"
        />
      </div>
      <div className="Page_main_block">
        <p>
          Основні принципи роботи ДІВСервіс:
          <br />
          <strong>Якість, професійне відношення до справи!</strong>
        </p>
        <p>
          Найкраща оцінка нашої роботи - <br />
          <strong>схвальні відгуки наших партнерів!</strong>
        </p>
      </div>
    </Page>
  );
};

export default ReviewsPage;
